.login__logo {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: contain;
    display: none;
  
    @include themify($themes) {
      background-image: themed('logoImg');
    }
  
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  
  .login__brand__logo {
    width: 170px;
    height: 60px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
    display: none;
  
    @include themify($themes) {
      background-image: themed('brandLogoImg');
    }
  
    @media screen and (min-width: 768px) {
      display: block;
    }
  }