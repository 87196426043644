.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  @include directify($directions) {
    text-align: directed('left');
  }
  h5.card__title-center {
    text-align: center;
  }
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}